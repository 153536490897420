import { useEffect } from "react";

import { useAppConfig } from '@hooks/appConfig';

export const useSetUserIndividualId = () => {
  const { user } = useAppConfig();

  useEffect(() => {
    const userIndividualId = (user?.isLoggedIn && user.riIndividualId) || null;
    window.localStorage.setItem("userIndividualId", userIndividualId || "");
  }, [user]);
};
